import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, NavController } from '@ionic/angular';
import { BaseService } from 'src/app/Services/base.service';
import { GlobalService } from 'src/app/Services/global.service';
import { NgZone  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
	selector: 'eds-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {

	@ViewChild(IonContent) content: IonContent;
	_service: any;
	_searchable: boolean
	searchValue: string;
	tmpSearchValue: string;
	@Input() title: string;
	@Input() displayParam1: any;
	@Input() fields: any[] = [];
	@Input() addEntryCallback: Function;
	_canopen: boolean
	_autoRefresh: number;

	tmp: string;

	update(){
		console.log("update")
		this.zone.run(() => {
			console.log('force update the screen');
		  });
		
		setTimeout(()=>{
			this.update()
		}, 5000 );
	}

	sanitize(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html) ;
	}

	_reorderDisabled: boolean = true;

	constructor(public global: GlobalService,
		private zone: NgZone, private sanitizer: DomSanitizer
	) { 

	}

	setFieldValue(obj, prop, value) {
		const oldobj = obj
		if ( prop == "customer.firstname" )
			console.log("setFieldValue",obj,prop,value)

		var parts = prop.split('.'),
			last = parts.pop(),
			l = parts.length,
			i = 1,
			current = parts[0];

		if (l === 0) {
			obj[prop] = value;
			console.log("setFieldValue 1",obj,prop,value)
			this._service.setEntryUpdate(oldobj)
			return
		}

		while ((obj = obj[current]) && i < l) {
			current = parts[i];
			i++;
		}

		if (obj) {
			obj[last] = value;
			console.log("setFieldValue 2",obj,prop,value)
			this._service.setEntryUpdate(oldobj)
		}		
	}

	getFieldValue(obj, prop) {
		return BaseService.getFieldValue(obj,prop)
	}

	sort(field) {
		if (!field.sort)
			field.sort = 1
		else if (field.sort == 1)
			field.sort = 2
		else
			field.sort = 1

		this.fields.forEach(f => {
			if (f != field) {
				f.sort = null
			}
		})

	}

	searchHandle = null
	search(keycode) {
		if (keycode == 13) {
			this.searchValue = this.tmpSearchValue
		}
	}

	getMaxWidth(field) {
		if ( field.type == "icon")
			return "38px"
		return ""
	}

	ngOnInit(): void {

	}

	getClassHeader(field, index) {
		return "header col" + index
	}

	getClass(field, i, j) {
		return "item col" + i + "-" + j
	}

	getPlaceHolder(field) {
		return field.placeholder ? field.placeholder : field.label
	}

	getType(field) {
		if (field.type == "function" || field.function )
			return "function"
		if (field.type == "icon")
			return "icon"
		if (field.type == "checkbox")
			return "checkbox"
		if (field.type == "currency")
			return "currency"
		if (field.type == "autocomplete")
			return "autocomplete"
		if (field.options)
			return "select"
		if (field.type == "date")
			return "date"
		if (field.type == "date-time")
			return "date-time"
		return "text"
	}

	clearSearch() {
		console.log("clearSearch")
		this.tmpSearchValue = ""
		this.searchValue = ""
	}

	displayEntries() {
		let sortedField = this.fields.find(x => x.sort)
		let tmp = this._service.getEntriesToDisplay(this.displayParam1, this.searchValue, sortedField)
		return tmp;
	}

	addEntry() {
		let newentry = this._service.addEntry()
		console.log("grid component addentry", newentry, this._service.getEntries())
		if (this.addEntryCallback)
			newentry = this.addEntryCallback(newentry)
		console.log("grid component entries", this._service.getEntries());
	}

	/*@Input()
	set addEntryCallback(addEntryCallback: (entry:any)=>void) {
		this._addEntryCallback = addEntryCallback;
	}*/

	@Input()
	set searchable(searchable: string) {
		this._searchable = false
		if (searchable.toLocaleLowerCase() == "true") {
			this._searchable = true
		}
	}

	@Input()
	set canopen(canopen: string) {
		this._canopen = false
		if (canopen.toLocaleLowerCase() == "true") {
			this._canopen = true
		}
	}


	@Input()
	set service(service: BaseService) {
		this._service = service;
	}

	@Input()
	set autoRefresh(timeout: number) {
		this._autoRefresh = timeout;
		setTimeout(()=>{
			this.update()
		}, 5000 );
	}

	/*auto complete functions*/
	autoCompleteProperty = "";
	autoCompleteSource = -1;
	currentFieldAutoComplete: any = null;
	currentEntryAutoComplete: any = null;
	itemsAutoComplete: any[] = [];
	itemsAutoCompleteCurrentIndex: number = -1;
	inputAutoComplete: any;
	autoCompleteTop: String = "-999px";
	autoCompleteLeft: String = "-999px";
	inputAutoCompleteBlur(): void {
		setTimeout(() => {
			this.currentEntryAutoComplete = null;
			this.itemsAutoComplete = []
			this.itemsAutoCompleteCurrentIndex = -1;
			this.autoCompleteSource = -1;
		}, 15000);
	}

	//retourne le nom du champ qui est utilisé pour l'affichage de l'auto complete
	//par défaut, le champ est identique à field
	//mais on peut passer un nom de champ différent
	getAutoCompleteFieldName(field) {
		if ( field.autocompleteproperty )
			return field.autocompleteproperty
		return field
	}

	selectedAutoComplete(selectedItem): void {
		console.log("selectedAutoComplete", selectedItem, this.itemsAutoCompleteCurrentIndex)

		let line = null;
		let item = null;

		const currentEntry = this.currentEntryAutoComplete;

		//field contient les infos du champ courant de la liste autocomplete
		const field = this.currentFieldAutoComplete;

		//on retrouve l'entrée en fonction de la valeur sélectionnée de la liste autocomplete
		const selectedEntry = field.findfunction( this.getAutoCompleteFieldName(field), selectedItem)

		field.autocompleteOnSelected(currentEntry, selectedEntry)

		this.itemsAutoComplete = []
		this.itemsAutoCompleteCurrentIndex = -1;
	}

	inputAutoCompleteChanged($event, currentEntry, indexrow, indexcol, field): void {
		this.autoCompleteSource = 0
		const value = $event.target.value;
		this.inputAutoComplete = $event.target;
		this.currentFieldAutoComplete = field;
		this.currentEntryAutoComplete = currentEntry;

		console.log("value", value)
		if (value.length <= 0) {
			this.itemsAutoComplete = [];
			return;
		}

		const columnheader = document.querySelector('.header.col' + indexcol) as HTMLDivElement | null;
		const fieldElement = document.querySelector('.item.col' + indexrow + "-" + indexcol) as HTMLDivElement | null;

		let headerRect = columnheader.getBoundingClientRect();
		let rect = $event.target.getBoundingClientRect()
		let parentRect = fieldElement.getBoundingClientRect();
		console.log("headerRect parentRect", headerRect, parentRect)
		this.autoCompleteTop = (parentRect.top - headerRect.top + parentRect.height) + "px";
		this.autoCompleteLeft = (parentRect.left - headerRect.left + 50) + "px";

		console.log("aaa", this.autoCompleteTop, this.autoCompleteLeft, '.item.col' + indexrow + "-" + indexcol)

		this.itemsAutoComplete = field.autocompletefunction( this.getAutoCompleteFieldName(field), value, currentEntry, indexrow)
		this.itemsAutoCompleteCurrentIndex = indexrow;
		console.log("this.itemsAutoComplete", this.itemsAutoComplete, indexrow, indexcol)
	}
	/*auto complete functions*/

	/*gestion popup date*/
	selectedPresentationType = "date"
	selectedEntry: any = null;
	selectedEntryDateFieldName = "";
	selectedEntryDate: any
	isDatePopoverOpened = false;
	@ViewChild('datepopover') popover;
	presentDatePopover(e: Event, entry, field, value) {
		const fieldname = field.field
		this.selectedPresentationType = field.type
		this.selectedEntry = entry;
		console.log("presentPopover", this.isDatePopoverOpened)
		this.popover.event = e;
		this.selectedEntryDate = value;
		this.selectedEntryDateFieldName = fieldname
		this.isDatePopoverOpened = true;
	}

	getPresentationDate() {
		return this.selectedPresentationType
	}

	dismissDatePopover() {
		console.log("this.selectedInvoiceDate", this.selectedEntryDate)
		this.isDatePopoverOpened = false
		if (this.selectedEntry[this.selectedEntryDateFieldName] != this.selectedEntryDate) {
			this.selectedEntry[this.selectedEntryDateFieldName] = this.selectedEntryDate;
			this.selectedEntry.db = 1;
			this._service.saveEntriesDelay();
		}
	}
	/*gestion popup date*/
}
