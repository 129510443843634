import { Component } from '@angular/core';
import { FacturePage } from './pages/facture/facture.page';
import { AppPagesService } from './Services/app-pages.service';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from './Services/authentication.service';
import { GlobalService } from './Services/global.service';
import { ParametresService } from './Services/parametres.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {

	constructor(
		public global: GlobalService,
		public parametresService: ParametresService,
		public appPagesService: AppPagesService,
		private storage: Storage, private auth: AuthenticationService) {

	}

	async ngOnInit() {
		await this.storage.create();
		console.log("a")
	}

	logout() {
		console.log("logout")
		this.auth.logout()
	}
}
