import { Injectable, Injector } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TimeTaskService } from './time-task.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {

  constructor(private injector: Injector) {

  }

  public mTimeTaskService: TimeTaskService

  preload(route: Route, fn: () => Observable<any>): Observable<any> {

    //import('../pages/time-tasks/time-tasks.module').then( m => m.TimeTasksPageModule)
    //return fn();
    if (route.data && route.data['preload']) {
      if (route.path == "time-tasks") {
        console.log("preload", route)
        this.mTimeTaskService = this.injector.get(TimeTaskService);
        return fn();
      }
    }
    return of(null);
  }
}