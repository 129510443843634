import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, CanMatch, Router, RouterStateSnapshot, UrlTree, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';




@Injectable({
  providedIn: 'root'
})


/*export class AuthGuard implements CanActivate {

  constructor(private auth: AuthenticationService, private router: Router) {

  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {



      if ( !this.auth.isAuthenticated()) {
        this.router.navigate(['/login'])
        return false;
      }
    
    return true;
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if ( !this.auth.isAuthenticated()) {
        this.router.navigate(['/login'])
        return false;
      }
    
    return true; 
  }
  
}*/



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanMatch {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("canMatch this.authService.isLoggedIn",this.authService.isLoggedIn,route,segments)
    let res = this.authService.ifLoggedIn2(route);
    console.log("ifLoggedIn2 res",res)
    if ( !res) {

      console.log("canMatch res false")
      //this.router.navigate(['/login'])
      return false;
    }
  
    console.log("canMatch res true")
  return true; 
  }

  /*cacnMatch(route: string): CanMatchResult {
    if (route.includes('protected-route') && !this.authService.isLoggedIn) {
      return { match: false, extras: { redirect: '/login' } };
    }

    return { match: true };
  }*/
}

