import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Services/auth.guard';
import { CustomPreloadingStrategyService } from './Services/custom-preloading-strategy.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule) 
  },

  {
    path: 'facture/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/facture/facture.module').then( m => m.FacturePageModule)
  },
  {
    path: 'factures', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/factures/factures.module').then( m => m.FacturesPageModule)
  },
  {
    path: 'clients', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'client/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'produits', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/produits/produits.module').then( m => m.ProduitsPageModule)
  },
  {
    path: 'produit/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/produit/produit.module').then( m => m.ProduitPageModule)
  },
  {
    path: 'time-tasks', data: {preload: true}, canMatch: [AuthGuard],
    loadChildren: () => import('./pages/time-tasks/time-tasks.module').then( m => m.TimeTasksPageModule)
  },
  {
    path: 'time-task/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/time-task/time-task.module').then( m => m.TimeTaskPageModule)
  },
  {
    path: 'parametres', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/parametres/parametres.module').then( m => m.ParametresPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })
  ],
  providers: [CustomPreloadingStrategyService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
