import { Injectable } from '@angular/core';
import { FacturePage } from '../pages/facture/facture.page';
import { Router } from '@angular/router';
import { ClientPage } from '../pages/client/client.page';
import { GlobalService } from './global.service';
import { ProduitPage } from '../pages/produit/produit.page';
import { TimeTaskService } from './time-task.service';

@Injectable({
	providedIn: 'root'
})
export class AppPagesService {
	public appPages = [
		//   { title: 'Facture', url: '/facture', Component: FacturePage, icon: 'mail' },
		/*  { title: 'Inbox', url: '/folder/inbox', icon: 'mail' },
		  { title: 'Outbox', url: '/folder/outbox', icon: 'paper-plane' },
		  { title: 'Favorites', url: '/folder/favorites', icon: 'heart' },
		  { title: 'Archived', url: '/folder/archived', icon: 'archive' },
		  { title: 'Trash', url: '/folder/trash', icon: 'trash' },
		  { title: 'Spam', url: '/folder/spam', icon: 'warning' },*/
	];
	public labels = [/*'Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'*/];

	constructor(public global: GlobalService, public router: Router) { 
		this.global.mainPageTitle.subscribe(pagetitle => {
			const page = this.appPages.find(page=>page.url == pagetitle.url)
			if ( page )
				page.title = pagetitle.title
		})

		this.global.authState.subscribe(value=> {
			console.log("AppPagesService this.global.authState",value,this.global.user)
			if (value) {
				let components = this.global.user["components"].toLocaleLowerCase().split(",")
				if ( components.includes("clients"))
					this.appPages.push( { title: 'Clients', url: '/clients', redirectUrl: '', /*Component: ClientPage,*/ icon: 'people', subnode: false } )
				if ( components.includes("factures"))
					this.appPages.push( { title: 'Factures', url: '/factures', redirectUrl: '', /*Component: FacturePage,*/ icon: 'documents', subnode: false } )
				if ( components.includes("produits"))
					this.appPages.push( { title: 'Produits', url: '/produits', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'cube', subnode: false } )
				if ( components.includes("timetasks"))
					this.appPages.push( { title: 'TimeLine', url: '/time-tasks', redirectUrl: '', /*Component: ProduitPage,*/ src: 'assets/icon/chrono.svg', subnode: false, iconClick: null } )

				this.appPages.push( { title: 'Paramètres', url: '/parametres', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'settings', subnode: false } )
				
			}
		})
		console.log("!!!! constructor AppPagesService")


	}

	closePage(url: String, redirectUrl: String = "") {
		console.log("closePage3",url);
		const index = this.appPages.findIndex(x=>x.url == url)
		console.log("index",index)
		if (index > -1) {
			this.appPages.splice(index, 1);
		}
		console.log("closePage2",JSON.stringify(this.appPages))

		if ( redirectUrl != "" )
			this.router.navigate([redirectUrl])
	}

	openPage(page: any) { //title,url,component,icon
		if (this.appPages.filter(x => x.url == page.url).length == 0) {

			let index = this.appPages.findIndex(x=>x.url == page.redirectUrl)
			if ( index > -1 ) {
				this.appPages.splice( index+1, 0, page )
			}
			else {
				this.appPages.push({ title: page.title, url: page.url, redirectUrl: page.redirectUrl, /*Component: page.component,*/ icon: page.icon, subnode: page.subnode });
			}
		}
		console.log("this.appPages", this.appPages)
		this.router.navigate([page.url])
	}

	displayPage(page: any) {
		console.log("display page", page)
		this.router.navigate([page.url])
	}
}
