<ion-app>
	<ion-split-pane contentId="main-content">
		<ion-menu contentId="main-content" type="overlay">
			<ion-content>
				<ion-list id="inbox-list">
					<ion-list-header>EDS Gestion</ion-list-header>
					<ion-note>{{this.global.infoSociete_raisonsociale}}</ion-note>
					<ion-icon (click)="logout()" size="large" class="logout" name="log-out-outline"></ion-icon>

					<ion-menu-toggle auto-hide="false" *ngFor="let p of this.appPagesService.appPages; let i = index">
						<ion-item (cclick)="this.appPagesService.displayPage(p)" routerDirection="root"
							[ngClass]="p.subnode ? 'subnode' : ''"  lines="none" detail="false"
							routerLinkActive="selected">
							<ion-icon src="{{p.src}}" (click)="this.global.iconClick.next(p)" class="starticon" aria-hidden="true" slot="start" [ios]="p.icon + '-outline'"
								[md]="p.icon + '-sharp'"></ion-icon>
							<ion-label [routerLink]="[p.url]">{{ p.title }}</ion-label>
							<ion-icon (click)="this.appPagesService.closePage(p.url,p.redirectUrl); $event.stopPropagation()" class="closeicon" name="close-outline"></ion-icon>
						</ion-item>
					</ion-menu-toggle>



				</ion-list>

				<!--
        <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of this.appPagesService.labels" lines="none">
            <ion-icon aria-hidden="true" slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list>
        -->
			</ion-content>
		</ion-menu>
		<ion-router-outlet id="main-content"></ion-router-outlet>
	</ion-split-pane>
</ion-app>