import { Injectable } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
import { InfoSociete } from '../types/info-societe';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	public infoSociete_raisonsociale: string = ""
	public infoSociete: InfoSociete;
	public loaded = new BehaviorSubject("");
	public authState = new BehaviorSubject(false);
	public iconClick = new BehaviorSubject(false);
	public mainPageTitle = new BehaviorSubject({url:"",title:""});
	public tableprefix: String;
	public userid: String;
	public user: any; //row from the database
	public users: any[]
	public userskeyvalue: any[]
	public pagetitles: any[] = []

	/*getMainTitle(url) {
		return this.pagetitles.find(p=>p.url==url)?.title
	}*/
	

	constructor(public http: HttpClient) {
		/*this.mainPageTitle.subscribe(pagetitle => {
			this.pagetitles.push(pagetitle)
			console.log("mainPageTitle",pagetitle,this.pagetitles)
		})*/
	}

	handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
		console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
		ev.detail.complete();
	}
}
