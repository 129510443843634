import * as moment from "moment";

export interface ITimeTaskLineLog {
    id: number;
    datedebut: any;
    datefin: any;
    totalsecond: number;
}

export class TimeTaskLineLog implements ITimeTaskLineLog {
    db: number = 0;
    id: number;
    datedebut: any;
    datefin: any;
    totalsecond: number;

    constructor() {
        this.db = 1
        const now = moment().format("YYYY-MM-DDTHH:mm:ss")
        this.datedebut = now
        this.datefin = null
        this.totalsecond = 0
        return this;
    }

    fromObj(log: ITimeTaskLineLog) {
        this.id = log.id
        this.datedebut = log.datedebut
        this.datefin = log.datefin
        this.totalsecond = log.totalsecond
        return this;
    }

    private handle = null;
    getTotalSeconds(): number {
        if ( this.datedebut && this.datefin )
            return this.totalsecond;

        this.totalsecond = moment().diff(moment(this.datedebut), 'seconds')
        if ( this.handle )
            clearTimeout( this.handle )
        this.handle = setTimeout(() => {console.log("getTotalSeconds timeout;");this.getTotalSeconds()},1000)
        return this.totalsecond
    }
}

/*$$$$*/

export interface ITimeTaskLine {
    id: number;
    idtimetask: number;
    iduser: number;
    libelle: String;
    logs: TimeTaskLineLog[] 
    categories: string;
}

export class TimeTaskLine implements ITimeTaskLine {
    db: number = 0; //1 add or update, 2 delete, 0 no changes
    id: number;
    idtimetask: number;
    iduser: number;
    libelle: String;
    logs: TimeTaskLineLog[]
    categories: string;
    
    constructor() {
        this.iduser = 0;
        this.libelle;
        this.logs = []
        this.categories = "";
    }

    getLastLogDate() {
        if (!this.logs || this.logs.length == 0)
            return undefined
        return this.logs.map(log=>log.datedebut).reduce((prev, current)=>{
            return (prev > current) ? prev : current
        })
    }

    getFirstLogDate() {
        if (!this.logs || this.logs.length == 0)
            return undefined
        return this.logs.map(log=>log.datedebut).reduce((prev, current)=>{
            return (prev < current) ? prev : current
        })
    }

    getTotalSeconds(): number {
        return this.logs.map(log=>log.getTotalSeconds()).reduce((partialSum, a) => partialSum + a, 0);
    }
    
    fromObj(line: ITimeTaskLine) {
        this.id = line.id;
        this.idtimetask = line.idtimetask
        this.iduser = line.iduser;
        this.libelle = line.libelle;
        if ( !this.libelle )
            this.libelle = ""
        this.logs = line.logs;
        if ( !this.logs )
            this.logs = []
        this.categories = line.categories;
        return this;
    }
}
