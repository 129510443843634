<ion-card>
	<ion-card-header>
		<ion-card-title>{{title}}</ion-card-title>
	</ion-card-header>

	<ion-card-content>
		<ion-item lines="none" class="recherchewrapper">
			<ion-input *ngIf="_searchable" class="searchinput" fill="outline" (keypress)="search($event.keyCode)"
				[(ngModel)]="tmpSearchValue" placeholder="Entrez votre recherche" type="text" autocorrect="off"
				autocapitalize="none" autocomplete="false">
			</ion-input>
			<ion-icon name="close-circle" (click)="clearSearch()"></ion-icon>
		</ion-item>

		<div>
			<ion-grid>
				<ion-row class="ion-align-items-center tableheader">
					<ion-col *ngIf="_canopen" style="max-width:38px"></ion-col>
					<ion-col *ngIf="!_reorderDisabled"></ion-col>
					<ion-col [style.max-width]="getMaxWidth(field)" [ngClass]="getClassHeader(field,i)" (click)="sort(field)"
						*ngFor="let field of fields; let i = index">
						{{field.label}}
						<ion-icon *ngIf="field.sort==1" name="chevron-up"></ion-icon>
						<ion-icon *ngIf="field.sort==2" name="chevron-down"></ion-icon>
					</ion-col>
					<ion-col style="max-width:38px"></ion-col>
				</ion-row>

				<ion-reorder-group [disabled]="_reorderDisabled"
					(ionItemReorder)="this.global.handleReorder($any($event))">

					<ion-reorder *ngFor="let entry of displayEntries(); let i = index">

						<ion-row class="ion-align-items-center invoiceline tableline">

							<ion-col *ngIf="_canopen" style="max-width:38px;text-align: center"><ion-icon
									(click)="this._service.open(entry)" name="folder-open-outline"
									size="large"></ion-icon>
							</ion-col>

							<ion-col *ngIf="!_reorderDisabled"><ion-icon name="reorder-three-outline"
									size="large"></ion-icon>
							</ion-col>

							<ion-col [style.max-width]="getMaxWidth(field)" [ngClass]="getClass(field,i,j)" *ngFor="let field of fields; let j = index">

								<ion-icon class="fieldicon" *ngIf="getType(field) == 'icon'" name="{{this.field.iconFunction(entry,field)}}" 
								(click)="this.field.callFunction(entry)"></ion-icon>

								<ion-checkbox *ngIf="getType(field) == 'checkbox'"
									(ionChange)="this._service.setEntryUpdate(entry)"
									[(ngModel)]="entry[ field.field ]">
								</ion-checkbox>

								<div *ngIf="getType(field) == 'function'">
									<div [innerHTML]="sanitize(this.field.function(entry))"></div>
								</div>
			
								<ion-input *ngIf="getType(field) == 'autocomplete'"
									(ngModelChange)="setFieldValue(entry,field.field, $event)"
									[ngModel]="getFieldValue( entry, field.field )"
									placeholder="{{getPlaceHolder(field)}}" type="text" autocorrect="off"
									autocapitalize="none" autocomplete="false" (ionBlur)="inputAutoCompleteBlur()"
									(ionInput)="inputAutoCompleteChanged($event,entry,i,j,field)">
								</ion-input>

								<ion-select *ngIf="getType(field) == 'select'"
									(ionChange)="this._service.setEntryUpdate(entry)" [(ngModel)]="entry[ field.field ]"
									placeholder="{{getPlaceHolder(field)}}" autocorrect="off" autocapitalize="none"
									autocomplete="false">

									<ion-select-option *ngFor="let option of field.options"
										[value]="option.key">{{option.value}}</ion-select-option>
								</ion-select>

								<ion-input *ngIf="getType(field) == 'date-time'"
									(ionInput)="this._service.setEntryUpdate(entry)"
									(click)="presentDatePopover($event,entry,field,entry[ field.field ])"
									value="{{ this._service.getDateTime( entry[ field.field ] ) }}" id="datetime"
									class="ion-text-end"></ion-input>

								<ion-input *ngIf="getType(field) == 'date'"
									(ionInput)="this._service.setEntryUpdate(entry)"
									(click)="presentDatePopover($event,entry,field,entry[ field.field ])"
									value="{{ this._service.getDate( entry[ field.field ] ) }}" id="date"
									class="ion-text-end"></ion-input>

								<ion-input *ngIf="getType(field) == 'currency'"
									(ionInput)="this._service.setEntryUpdate(entry)" [(ngModel)]="entry[ field.field ]"
									placeholder="{{getPlaceHolder(field)}}" type="number" inputmode="decimal"
									autocorrect="off" autocapitalize="none" autocomplete="false">
								</ion-input>

								<ion-input *ngIf="getType(field) == 'text'"
									(ngModelChange)="setFieldValue(entry,field.field, $event)"
									[ngModel]="getFieldValue( entry, field.field )"
									placeholder="{{getPlaceHolder(field)}}" type="{{getType(field)}}" autocorrect="off"
									autocapitalize="none" autocomplete="false">
								</ion-input>
							</ion-col>


							<ion-col style="max-width:38px;text-align: center;"><ion-icon name="trash-outline"
									(click)="this._service.deleteEntry(entry)"></ion-icon></ion-col>

						</ion-row>

					</ion-reorder>
				</ion-reorder-group>


				<ion-list #autoCompleteList [style.top]="autoCompleteTop" [style.left]="autoCompleteLeft"
					[hidden]="itemsAutoComplete.length <= 0" class="autocompletelist">
					<ion-item (click)="selectedAutoComplete(itemAutoComplete)"
						*ngFor="let itemAutoComplete of itemsAutoComplete">
						{{itemAutoComplete}}
					</ion-item>
				</ion-list>

				<ion-popover #datepopover [isOpen]="isDatePopoverOpened" (didDismiss)="dismissDatePopover()">
					<ng-template>
						<ion-datetime presentation="{{getPresentationDate()}}" [(ngModel)]="selectedEntryDate" locale="fr-FR"></ion-datetime>
					</ng-template>
				</ion-popover>

				<ion-row>
					<ion-button [disabled]="false" (click)="addEntry()">Ajouter</ion-button>
					<ion-button [disabled]="false"
						(click)="this._reorderDisabled = !_reorderDisabled;">Réorganiser</ion-button>
				</ion-row>


			</ion-grid>
		</div>

		<ng-content></ng-content>

	</ion-card-content>
</ion-card>