import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './Services/authentication.service';
import { AuthGuard } from './Services/auth.guard';
import { Storage } from '@ionic/storage';
import { GlobalService } from './Services/global.service';
import { GridModule } from './components/grid/grid.module';
import { ParametresService } from './Services/parametres.service';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, 
		FormsModule,HttpClientModule,GridModule,
		ReactiveFormsModule],
	providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Storage, AuthGuard, 
		GlobalService, ParametresService,
		AuthenticationService,],
	bootstrap: [AppComponent],




})
export class AppModule { }
