import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { of } from 'rxjs';

/*@Injectable({
	providedIn: 'root'
})*/
export class BaseService {

	basetable: string = "unknownbasetable";
	arraytables: any[] = []


	constructor(public global: GlobalService, public appPagesService: AppPagesService, public router: Router, public http: HttpClient) {

	}

	init(basetable, callbacknewentry, arraytables: any[] = []) { //must be called in child constructor
		this.basetable = basetable;
		this.arraytables = arraytables;
		this.global.authState.subscribe(value => {
			//console.log("BaseService this.global.authState", value)
			if (value)
				this.load(basetable, callbacknewentry)
		})

		//console.log("!!!! constructor BaseService")
	}

	entries: any[] = [];			//all customers
	currentEntry = null;	//current customer

	addEntry(entry: any) {
		//console.log("addEntry", entry, this.entries)
		entry.id = this.getNewId();
		entry.db = 1;
		this.entries.push(entry);
		this.saveEntriesDelay()
		return entry
	}

	getEntries() {
		return this.entries;
	}

	getEntriesToDisplay(idcustomer = null, search = null, sortedField = null) {
		//console.log("getEntriesToDisplay",idcustomer,search)

		if (idcustomer)
			return this.entries.filter(x => x.db != 2 && x.idcustomer == idcustomer)
		return this.entries.filter(x => {
			let res = false
			res = (x.db != 2)
			if (!res)
				return false
			if (idcustomer)
				if (x.idcustomer != idcustomer)
					return false;
			if (search) {
				const xsearch = search.toLocaleLowerCase()
				let property: keyof typeof x;
				for (property in x) {
					if (typeof x[property] == "string" && x[property].toLocaleLowerCase().includes(xsearch))
						return true;
				}
				return false;
			}
			return true
		})
	}

	//get the property value of an object. 
	//The property can be customer.firstname for exampel
	static getFieldValue(obj, prop) {
		//	if ( prop.includes("lastname"))
		//		console.log("static getFieldValue",obj,prop)
		var parts = prop.split('.'),
			last = parts.pop(),
			l = parts.length,
			i = 1,
			current = parts[0];

		if (l === 0) return obj[prop];

		while ((obj = obj[current]) && i < l) {
			current = parts[i];
			i++;
		}

		if (obj) {
			return obj[last];
		}
	}

	findByProperty(propertyname, value) {
		if (!propertyname) {
			console.error("Missing findByProperty propertyname")
			return []
		}
		const entry = this.entries.find(x => BaseService.getFieldValue(x, propertyname) == value)
		//console.log("findByProperty",propertyname,value,this.entries,entry)
		return entry;
	}

	getAllByPropery(propertyname, value) {
		value = value.toLocaleLowerCase()
		if (!propertyname) {
			console.error("Missing findByProperty propertyname")
			return []
		}
		console.log("getAllByPropery", propertyname, value, this.entries)
		return this.entries.filter(x => BaseService.getFieldValue(x, propertyname).toLocaleLowerCase().includes(value)).map(x => BaseService.getFieldValue(x, propertyname))
	}

	getNewCustomId(array: any[]): number {
		let res = array
			.map((entry) => entry.id)
			.sort((entryId1, entryId2) => {
				return entryId1 > entryId2 ? -1 : 1;
			})[0] + 1
		return (
			isNaN(res) ? 0 : res
		);
	}

	getNewId(): number {
		let res = this.getEntries()
			.map((entry) => entry.id)
			.sort((entryId1, entryId2) => {
				return entryId1 > entryId2 ? -1 : 1;
			})[0] + 1
		return (
			isNaN(res) ? 0 : res
		);
	}

	getDate(field) {
		if (!field)
			return "";
		return moment(field).format("DD/MM/YYYY")
	}

	getDateTime(field) {
		if (!field)
			return "";
		return moment(field).format("DD/MM/YYYY HH:mm:ss")
	}

	getTitle(entry: any = null) {
		throw new Error("must be overriden");
	}

	findEntry(id): any {
		this.currentEntry = null;
		this.currentEntry = this.entries.find(entry => entry.id == id)
		return this.currentEntry
	}

	openold(entry: any = null) {
		throw new Error("must be overriden");
	}

	open(entry) {
		this.currentEntry = entry;
		//console.log("open", entry,this.getSingleIcon())

		this.appPagesService.openPage({
			title: this.getTitle(entry), url: this.getSingleUrl(), redirectUrl: this.getListUrl(), icon: this.getSingleIcon(), subnode: true
		});
	}

	close(entry, redirect: String = "") {
		throw new Error("must be overriden");
	}

	setEntryUpdate(entry): void {
		console.log("setEntryUpdate", entry)
		entry.db = 1
		this.saveEntriesDelay();
	}

	deleteEntry(entry) {
		entry.db = 2
		this.saveEntriesDelay()
	}

	handleSave = null;
	saveEntriesDelay() {
		if (this.handleSave != null) {
			clearTimeout(this.handleSave)
		}
		this.handleSave = setTimeout(() => { this.save() }, 5000);
	}


	iconClick(page) {
		throw new Error("iconClick must be overriden");
	}

	getSingleUrl() {
		throw new Error("must be overriden");
	}

	getListUrl() {
		throw new Error("must be overriden");
	}

	getSingleIcon() {
		throw new Error("must be overriden");
	}

	getListIcon() {
		throw new Error("must be overriden");
	}

	log(...txt) {
		if (this.basetable.includes("timetaskline"))
			console.log(txt)
	}

	onLoadingFinished() {

	}

	async load(basetable, callbacknewentry) {
		
			this.entries = [];

			const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
			const params = new HttpParams()
				.set('table', (this.global.tableprefix + basetable))

			console.log("💾 " + basetable + " load")

			const url = "https://restapi11.edservices.fr/api/process.php"
			const data = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
			//console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " load", data);
			if (data != null) {
				data.forEach(ent => {
					let dbentry = JSON.parse(ent.data)
					this.log("💾 dbentry", dbentry)

					this.log("call callbacknewentry")
					let newentry = callbacknewentry(dbentry, basetable)
					this.log("call callbacknewentry res", newentry)
					this.entries.push(newentry)
				})
				this.log("💾 entries " + basetable, this.entries)
			}
			//on charge les tableaux si configuré

			for (let i = 0; i < this.arraytables.length; i++) {
				const x = this.arraytables[i]
				const params = new HttpParams()
					.set('table', (this.global.tableprefix + x.table))
				const url = "https://restapi11.edservices.fr/api/process.php"
				const data2 = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
				if (data2 != null) {
					this.log("💾 💾 💾 💾 data2 ", data2)
					data2.forEach(entarray => {
						let dbentry = JSON.parse(entarray.data)
						let idparent = entarray["idparent"]
						this.log("💾 dbentry2 " + x.table, dbentry, entarray)

						this.log("call callbacknewentry2")
						let newentry = callbacknewentry(dbentry, x.table)
						this.log("call callbacknewentry2 res", newentry)
						let parententry = this.entries.find(x => x.id == idparent)
						this.log("parententry", idparent, parententry, x.prop)
						if (!parententry[x.prop])
							parententry[x.prop] = []
						parententry[x.prop].push(newentry)

						this.log("💾 dbentry2 " + x.table, dbentry)
					})
				}
			}




		//fin du chargement des tableaux
		this.log("💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾 end load", this.entries)
		this.onLoadingFinished()
		this.global.loaded.next(this.basetable)


	}

	save() {
		let basetable = this.basetable;
		return new Promise(resolve => {
			console.log("save", basetable, this.entries)
			this.entries.forEach((entry) => {

				let arrays = []

				if (entry.db != 1)
					return;
				const key = { id: entry.id }
				const data = { ...entry }

				console.log("save " + basetable, entry)

				//on sauvegarde les tableaux automiatuqment dans une table differente
				//le contenu de chaque tableau est conservé dans une tableau différent
				//et ensuite supprimé de l'entry de base pour ne pas qu'il soit sauvegardé
				if (this.arraytables.length > 0) {
					Object.keys(data).forEach(k => {
						if (Array.isArray(data[k])) {
							console.log("data array")
							arrays.push({ prop: k, array: data[k] })
							delete (data[k])
						}
					})
					console.log("data", data)
				}

				//sauvegarde de l'entrée principal
				const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
				const body = new HttpParams()
					.set('method', "add")
					.set('table', this.global.tableprefix + basetable)
					.set('key', JSON.stringify(key))
					.set('data', JSON.stringify(data))
				const url = "https://restapi11.edservices.fr/api/process.php"
				this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
					console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " update ", data);
					if (data != null) {
						entry.db = 0;
					}

					//on sauvegarde les tableaux si configuré
					this.arraytables.forEach(x => {
						const tosave = { ...entry }[x.prop]
						console.log("save " + this.global.tableprefix + x.table)

						tosave.forEach((linetosave) => {

							const key = { id: linetosave.id, idparent: entry.id }
							const data = linetosave
							console.log("save key", key, data)
							const body = new HttpParams()
								.set('method', "add")
								.set('table', this.global.tableprefix + x.table)
								.set('key', JSON.stringify(key))
								.set('data', JSON.stringify(data))

							const url = "https://restapi11.edservices.fr/api/process.php"
							this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
								if (data != null) {
									linetosave.db = 0;
								}
							}, error => {
								//console.log("http error", error)
							});
						})
					})
				}, error => {
					//console.log("http error", error)
				});

			})

			this.entries.forEach((entry) => {
				if (entry.db != 2)
					return;
				const key = { id: entry.id }

				//console.log("save " + basetable)

				const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")

				const body = new HttpParams()
					.set('method', "delete")
					.set('table', this.global.tableprefix + basetable)
					.set('key', JSON.stringify(key))

				//console.log(basetable + " delete", body.toString())

				const url = "https://restapi11.edservices.fr/api/process.php"
				this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
					//console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " delete ", data);
					if (data != null) {
						//console.log("delete ok", data);
						entry.db = 2;
					}
				}, error => {
					//console.log("http error", error)
				});

			})
		});
	}
}
