import { Customer } from "./customer";

export interface ITimeTask {
    id: number;
    idparent: number;
    customer: Customer
    libelle: string;
}

export class TimeTask implements ITimeTask {
    db: number = 0; //1 add or update, 2 delete, 0 no changes
    id: number;
    idparent: number;
    customer: Customer;
    libelle: string;

    constructor() {

    }

    public fromObj(timeTask: ITimeTask) {
        this.id = timeTask.id;
        this.idparent = timeTask.idparent
        this.customer = timeTask.customer
        this.libelle = timeTask.libelle
        return this;
    }

    getTime() {
        return "?"
    }
}